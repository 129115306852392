import { emptyApi as api } from "./emptyApi";
import { imageToBase64, objectToFormData } from "../../utils/Utils";
import { currentOrganizationIdSelector, useCurrentCollectionId } from "../GeneralSlice";
import { ThradInfo } from "./threads";
import { useEffect, useMemo } from "react";
import { useAllDocumentTasks, useCompletedTasks } from "state/TasksSlice";
import { useAllDocumentTasks as useWFAllDocumentTasks, useCompletedTasks as useWFCompletedTasks } from "state/WFTasksSlice";

const collectionsRtkApi = api
    .enhanceEndpoints({
        addTagTypes: [
            "privileges",
            "visibility",
            "list",
            "files",
            "settings",
            "prompt",
            "modelName",
            "modelPic",
            "pipeline",
            "language",
            "association",
            "uploads",
            "lists",
            "listDocuments",
            "workingFiles",
            "wf_uploads"
        ]
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getAllCollections: build.query<CollectionDescription[], GetAllCollectionsArgs>({
                query: (args) => ({
                    url: `/collections`,
                    params: args
                }),
                providesTags: ["list"]
            }),
            createCollection: build.mutation<any, CreateCollectionArgs>({
                query: (queryArg) => {
                    return {
                        url: `/collections`,
                        method: "POST",
                        params: queryArg.params,
                        body: queryArg.body
                    };
                },
                invalidatesTags: ["list"]
            }),
            getCollectionInfo: build.query<CollectionInfo, CollectionIdArgs>({
                query: (args) => ({
                    url: `/collections/${args.collection_id}`
                })
            }),
            updateCollectionName: build.mutation<any, UpdateCollectionNameArgs>({
                query: (args) => {
                    return {
                        url: `/collections/${args.collection_id}/name`,
                        method: "PUT",
                        params: {
                            collection_name: args.collection_name
                        }
                    };
                },
                async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                    const patchResult = dispatch(
                        collectionsRtkApi.util.updateQueryData("getCollectionInfo", { collection_id: arg.collection_id }, (draft) => {
                            draft.name = arg.collection_name;
                        })
                    );
                    queryFulfilled.catch(patchResult.undo);
                },
                invalidatesTags: ["list"]
            }),
            deleteCollection: build.mutation<any, CollectionIdArgs>({
                query: (args) => {
                    return {
                        url: `/collections/${args.collection_id}`,
                        method: "DELETE"
                    };
                },
                onQueryStarted: (arg, { dispatch, queryFulfilled, getState }) => {
                    const orgId = currentOrganizationIdSelector(getState());
                    const patchResult = dispatch(
                        collectionsRtkApi.util.updateQueryData("getAllCollections", { org: orgId }, (draft: any[] | undefined) => {
                            if (draft) {
                                return draft.filter((el) => el.uuid !== arg.collection_id);
                            }
                        })
                    );
                    queryFulfilled.catch(patchResult.undo);
                },
                invalidatesTags: ["list"]
            }),
            getCollectionPrivileges: build.query<CollectionPrivilegesInfo, CollectionIdArgs>({
                query: (args) => ({
                    url: `/collections/${args.collection_id}/privileges`
                }),
                providesTags: (result, error, arg) => [{ type: "privileges", id: arg.collection_id }]
            }),
            addCollectionPrivileges: build.mutation<any, UpdateCollectionPrivilegsArgs>({
                query: (args) => {
                    return {
                        url: `/collections/${args.collection_id}/privileges`,
                        method: "POST",
                        body: args.body
                    };
                },
                invalidatesTags: (result, error, arg) => [{ type: "privileges", id: arg.collection_id }]
            }),
            removeCollectionPrivileges: build.mutation<any, UpdateCollectionPrivilegsArgs>({
                query: (args) => {
                    return {
                        url: `/collections/${args.collection_id}/privileges`,
                        method: "DELETE",
                        body: args.body
                    };
                },
                invalidatesTags: (result, error, arg) => [{ type: "privileges", id: arg.collection_id }]
            }),
            getFiles: build.query<any, CollectionIdArgs>({
                query: (queryArg) => ({
                    url: `/collections/${queryArg.collection_id}/files`
                }),
                providesTags: (result, error, arg) => [{ type: "files", id: arg.collection_id }]
            }),
            uploadFile: build.mutation<UploadResponse, UploadFileArgs>({
                query: (queryArg) => {
                    return {
                        url: `/collections/${queryArg.collection_id}/files`,
                        method: "POST",
                        params: { current_org_uuid: queryArg.current_org_uuid },
                        body: objectToFormData(queryArg, "collection_id", "current_org_uuid")
                    };
                },
                invalidatesTags: (result, error, args) => [{ type: "uploads", id: args.collection_id }]
            }),
            uploadGist: build.mutation<UploadResponse, UploadGistArgs>({
                query: (queryArg) => {
                    return {
                        url: `/collections/${queryArg.collection_id}/gists`,
                        method: "POST",
                        params: { current_org_uuid: queryArg.current_org_uuid },
                        body: objectToFormData(queryArg, "collection_id", "current_org_uuid")
                    };
                },
                invalidatesTags: (result, error, args) => [{ type: "uploads", id: args.collection_id }]
            }),
            getGist: build.query<GetGistResponse, GetGistArgs>({
                query: (queryArg) => {
                    return {
                        url: `/collections/${queryArg.collection_id}/gists/${queryArg.doc_uid}`
                    };
                }
            }),
            updateGist: build.mutation<UploadResponse, UpdateGistArgs>({
                query: (queryArg) => {
                    return {
                        url: `/collections/${queryArg.collection_id}/gists/${queryArg.doc_uid}`,
                        method: "PUT",
                        params: { current_org_uuid: queryArg.current_org_uuid },
                        body: objectToFormData(queryArg, "collection_id", "doc_uid", "current_org_uuid")
                    };
                }
            }),
            uploadNote: build.mutation<UploadResponse, UploadGistArgs>({
                query: (queryArg) => {
                    return {
                        url: `/collections/${queryArg.collection_id}/notes`,
                        method: "POST",
                        params: { current_org_uuid: queryArg.current_org_uuid },
                        body: objectToFormData(queryArg, "collection_id", "current_org_uuid")
                    };
                },
                invalidatesTags: (result, error, args) => [{ type: "uploads", id: args.collection_id }]
            }),
            getNote: build.query<GetGistResponse, GetGistArgs>({
                query: (queryArg) => {
                    return {
                        url: `/collections/${queryArg.collection_id}/notes/${queryArg.doc_uid}`
                    };
                }
            }),
            updateNote: build.mutation<UploadResponse, UpdateGistArgs>({
                query: (queryArg) => {
                    return {
                        url: `/collections/${queryArg.collection_id}/notes/${queryArg.doc_uid}`,
                        method: "PUT",
                        params: { current_org_uuid: queryArg.current_org_uuid },
                        body: objectToFormData(queryArg, "collection_id", "doc_uid", "current_org_uuid")
                    };
                },
                invalidatesTags: (result, error, args) => [{ type: "uploads", id: args.collection_id }]
            }),
            uploadRec: build.mutation<UploadResponse, UploadRecArgs>({
                query: (queryArg) => {
                    return {
                        url: `/collections/${queryArg.collection_id}/recs`,
                        method: "POST",
                        params: { current_org_uuid: queryArg.current_org_uuid, is_note: queryArg.is_note },
                        body: objectToFormData(queryArg, "collection_id", "is_note")
                    };
                },
                invalidatesTags: (result, error, args) => [{ type: "uploads", id: args.collection_id }]
            }),
            deleteFile: build.mutation<any, DeleteFileArgs>({
                query: (queryArg) => {
                    return {
                        url: `/collections/${queryArg.collection_id}/files`,
                        method: "DELETE",
                        body: [["file_uid", queryArg.file_id]]
                    };
                },
                invalidatesTags: (result, error, arg) => [{ type: "files", id: arg.collection_id }]
            }),
            getDocuments: build.query<KnowledgeDocument[], CollectionIdArgs>({
                query: (queryArg) => ({
                    url: `/collections/${queryArg.collection_id}/documents`
                }),
                providesTags: (result, error, arg) => [{ type: "files", id: arg.collection_id }]
            }),
            getDocumentsInUpload: build.query<UploadInfo[], CollectionIdArgs>({
                query: (args) => ({
                    url: `/collections/${args.collection_id}/uploads`
                }),
                providesTags: (result, error, args) => [{ type: "uploads", id: args.collection_id }]
            }),
            removeDocumentsInUpload: build.mutation<any, RemoveUploadArgs>({
                query: (args) => ({
                    url: `/collections/${args.collection_id}/uploads/${args.doc_uid}`,
                    method: "DELETE"
                }),
                onQueryStarted: (arg, { dispatch, queryFulfilled, getState }) => {
                    const patchResult = dispatch(
                        collectionsRtkApi.util.updateQueryData(
                            "getDocumentsInUpload",
                            { collection_id: arg.collection_id },
                            (draft: any[] | undefined) => {
                                if (draft) {
                                    return draft.filter((el) => el.document_id !== arg.doc_uid);
                                }
                            }
                        )
                    );
                    queryFulfilled.catch(patchResult.undo);
                },
                invalidatesTags: (result, error, args) => [{ type: "uploads", id: args.collection_id }]
            }),
            removeTaskInUpload: build.mutation<any, RemoveUploadArgs>({
                query: (args) => ({
                    url: `/collections/${args.collection_id}/uploads/${args.doc_uid}/task`,
                    method: "DELETE"
                })
            }),
            getDocumentVisibility: build.query<boolean, GetDocumentVisibilityArgs>({
                query: (queryArg) => ({
                    url: `/collections/${queryArg.collection_id}/document-visibility/${queryArg.doc_uuid}`
                }),
                providesTags: (result, error, arg) => [{ type: "visibility", id: arg.doc_uuid }]
            }),
            setDocumentVisibility: build.mutation<any, SetDocumentVisibilityArgs>({
                query: (queryArg) => {
                    return {
                        url: `/collections/${queryArg.collection_id}/document-visibility/${queryArg.doc_uuid}`,
                        method: "POST",
                        params: { visibility: queryArg.visibility }
                    };
                },
                invalidatesTags: (result, error, arg) => [{ type: "visibility", id: arg.doc_uuid }, "files"]
            }),
            updateDocumentTitle: build.mutation<any, UpdateDocumentTitleArgs>({
                query: (queryArg) => {
                    return {
                        url: `/collections/${queryArg.collection_id}/document-title/${queryArg.doc_uid}`,
                        method: "PUT",
                        params: queryArg.params,
                        body: objectToFormData(queryArg.body)
                    };
                },
                invalidatesTags: (result, error, arg) => [
                    { type: "files", id: arg.collection_id },
                    { type: "uploads", id: arg.collection_id }
                ]
            }),
            getDownloadLink: build.query<any, DownloadFileArgs>({
                query: (queryArg) => ({
                    url: `/collections/${queryArg.collection_id}/download-link`,
                    params: {
                        file_id: queryArg.file_id
                    }
                })
            }),
            getCollectionConfig: build.query<CollectionSettings, CollectionIdArgs>({
                query: (queryArg) => ({
                    url: `/collections/${queryArg.collection_id}/config`
                }),
                providesTags: (result, error, arg) => [{ type: "settings", id: arg.collection_id }]
            }),
            updateCollectionConfig: build.mutation<any, UpdateCollectionSettingsArgs>({
                query: (args) => ({
                    url: `collections/${args.collection_id}/config`,
                    method: "PUT",
                    body: args.payload,
                    headers: {
                        "Content-Type": "application/json"
                    }
                }),
                invalidatesTags: (result, error, arg) => [{ type: "settings", id: arg.collection_id }]
            }),
            getPrompt: build.query<string, CollectionIdArgs>({
                query: (queryArg) => ({
                    url: `/collections/${queryArg.collection_id}/prompt`
                }),
                providesTags: (result, error, arg) => [{ type: "prompt", id: arg.collection_id }]
            }),
            updatePrompt: build.mutation<any, UpdatePromptArgs>({
                query: (args) => ({
                    url: `collections/${args.collection_id}/prompt`,
                    method: "PUT",
                    params: args.payload
                }),
                invalidatesTags: (result, error, arg) => [{ type: "prompt", id: arg.collection_id }]
            }),
            deletePrompt: build.mutation<any, CollectionIdArgs>({
                query: (args) => ({
                    url: `collections/${args.collection_id}/prompt`,
                    method: "DELETE"
                }),
                invalidatesTags: (result, error, arg) => [{ type: "prompt", id: arg.collection_id }]
            }),
            getModelName: build.query<string, CollectionIdArgs>({
                query: (queryArg) => ({
                    url: `/collections/${queryArg.collection_id}/model-name`
                }),
                transformResponse: (response: GetModelNameResponse) => response.model_name,
                providesTags: (result, error, arg) => [{ type: "modelName", id: arg.collection_id }]
            }),
            updateModelName: build.mutation<any, UpdateModelNameArgs>({
                query: (args) => ({
                    url: `collections/${args.collection_id}/model-name`,
                    method: "PUT",
                    params: args.payload
                }),
                invalidatesTags: (result, error, arg) => [{ type: "modelName", id: arg.collection_id }]
            }),
            getModelPic: build.query<string, CollectionIdArgs>({
                query: (queryArg) => ({
                    url: `/collections/${queryArg.collection_id}/model-pic`,
                    responseHandler: async (response: Response) => {
                        const image = await response.blob();
                        return imageToBase64(image);
                    }
                }),
                providesTags: (result, error, arg) => [{ type: "modelPic", id: arg.collection_id }]
            }),
            updateModelPic: build.mutation<any, UpdateModelPicArgs>({
                query: (args) => ({
                    url: `collections/${args.collection_id}/model-pic`,
                    method: "PUT",
                    params: args.params,
                    body: objectToFormData(args.payload)
                }),
                invalidatesTags: (result, error, arg) => [{ type: "modelPic", id: arg.collection_id }]
            }),
            deleteModelPic: build.mutation<any, CollectionIdArgs>({
                query: (args) => ({
                    url: `collections/${args.collection_id}/model-pic`,
                    method: "DELETE"
                }),
                invalidatesTags: (result, error, arg) => [{ type: "modelPic", id: arg.collection_id }]
            }),
            getPipelineType: build.query<PipelineTypeResponse, CollectionIdArgs>({
                query: (args) => ({
                    url: `/collections/${args.collection_id}/pipeline-type`
                }),
                providesTags: (result, error, arg) => [{ type: "pipeline", id: arg.collection_id }]
            }),
            updatePipelineType: build.mutation<any, UpdatePipelineTypeArgs>({
                query: (args) => ({
                    url: `collections/${args.collection_id}/pipeline-type`,
                    method: "PUT",
                    params: args.params
                }),
                invalidatesTags: (result, error, arg) => [{ type: "pipeline", id: arg.collection_id }]
            }),
            deletePipelineType: build.mutation<any, CollectionIdArgs>({
                query: (args) => ({
                    url: `collections/${args.collection_id}/pipeline-type`,
                    method: "DELETE"
                }),
                invalidatesTags: (result, error, arg) => [{ type: "pipeline", id: arg.collection_id }]
            }),
            getModelLanguage: build.query<ModelLanguageResponse, CollectionIdArgs>({
                query: (args) => ({
                    url: `/collections/${args.collection_id}/model-language`
                }),
                providesTags: (result, error, arg) => [{ type: "language", id: arg.collection_id }]
            }),
            updateModelLanguage: build.mutation<any, UpdateModelLanguageArgs>({
                query: (args) => ({
                    url: `collections/${args.collection_id}/model-language`,
                    method: "PUT",
                    params: args.params
                }),
                invalidatesTags: (result, error, arg) => [{ type: "language", id: arg.collection_id }]
            }),
            deleteModelLanguage: build.mutation<any, CollectionIdArgs>({
                query: (args) => ({
                    url: `collections/${args.collection_id}/model-language`,
                    method: "DELETE"
                }),
                invalidatesTags: (result, error, arg) => [{ type: "language", id: arg.collection_id }]
            }),
            getAssociatedCollections: build.query<string[], CollectionIdArgs>({
                query: (args) => ({
                    url: `/collections/${args.collection_id}/cross-retrieve-collections`
                }),
                providesTags: (result, error, arg) => [{ type: "association", id: arg.collection_id }]
            }),
            addAssociatedCollection: build.mutation<any, AddAssociatedCollectionArgs>({
                query: (args) => ({
                    url: `collections/${args.collection_id}/cross-retrieve-collections`,
                    method: "POST",
                    params: args.params
                }),
                invalidatesTags: (result, error, arg) => [{ type: "association", id: arg.collection_id }]
            }),
            removeAssociatedCollection: build.mutation<any, RemoveAssociatedCollectionArgs>({
                query: (args) => ({
                    url: `collections/${args.collection_id}/cross-retrieve-collections/${args.cross_retrieve_collection_id}`,
                    method: "DELETE",
                    params: args.params
                }),
                invalidatesTags: (result, error, arg) => [{ type: "association", id: arg.collection_id }]
            }),
            getCollectionFeedbacks: build.query<FeedbackMessageInfo[], CollectionIdArgs>({
                query: (args) => ({
                    url: `/collections/${args.collection_id}/feedbacks`
                }),
                providesTags: (result, error, arg) => [{ type: "feedbacks", id: arg.collection_id }]
            }),
            setCollectionFeedbackRead: build.mutation<any, SetFeedbackReadArgs>({
                query: (args) => ({
                    url: `collections/${args.collection_id}/feedbacks/${args.message_uuid}/feedback-read`,
                    method: "PUT",
                    params: args.params
                }),
                async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                    const feedbackResult = dispatch(
                        collectionsRtkApi.util.updateQueryData("getCollectionFeedbacks", { collection_id: arg.collection_id }, (draft) => {
                            draft.find((v) => v.uuid === arg.message_uuid).feedback_read = arg.params.feedback_read;
                        })
                    );

                    const threadResult = dispatch(
                        collectionsRtkApi.util.updateQueryData(
                            "getCollectionFeedbackThread",
                            { collection_id: arg.collection_id, thread_uuid: arg.thread_uuid },
                            (draft) => {
                                const message = draft.messages.find((v) => v.uuid === arg.message_uuid);
                                message.feedback_read = arg.params.feedback_read;
                            }
                        )
                    );

                    queryFulfilled.catch(() => {
                        feedbackResult.undo();
                        threadResult.undo();
                    });
                },
                invalidatesTags: (result, error, arg) => [
                    { type: "feedbacks", id: arg.collection_id },
                    { type: "history", id: arg.thread_uuid }
                ]
            }),
            getCollectionFeedbackThread: build.query<ThradInfo, GetFeedbakcThreadArgs>({
                query: (args) => ({
                    url: `collections/${args.collection_id}/feedbacks/${args.thread_uuid}/thread`
                }),
                providesTags: (result, error, arg) => [{ type: "history", id: arg.thread_uuid }]
            }),
            getCollectionLists: build.query<ListInfo[], CollectionIdArgs>({
                query: (args) => ({
                    url: `collections/${args.collection_id}/lists`
                }),
                providesTags: (result, error, arg) => [{ type: "lists", id: arg.collection_id }]
            }),
            createCollectionList: build.mutation<ListInfo, CreateListArgs>({
                query: (args) => ({
                    url: `collections/${args.collection_id}/lists`,
                    method: "POST",
                    params: args.params
                }),
                invalidatesTags: (result, error, arg) => [{ type: "lists", id: arg.collection_id }]
            }),
            editCollectionList: build.mutation<ListInfo, UpdateListArgs>({
                query: (args) => ({
                    url: `collections/${args.collection_id}/lists/${args.list_id}`,
                    method: "PUT",
                    params: args.params
                }),
                invalidatesTags: (result, error, arg) => [{ type: "lists", id: arg.collection_id }]
            }),
            deleteCollectionList: build.mutation<any, ListIdArgs>({
                query: (args) => ({
                    url: `collections/${args.collection_id}/lists/${args.list_id}`,
                    method: "DELETE"
                }),
                invalidatesTags: (result, error, arg) => [{ type: "lists", id: arg.collection_id }]
            }),
            getCollectionListDocuments: build.query<KnowledgeDocument[], ListIdArgs>({
                query: (args) => ({
                    url: `collections/${args.collection_id}/lists/${args.list_id}/documents`
                }),
                providesTags: (result, error, arg) => [{ type: "listDocuments", id: arg.list_id }]
            }),
            addCollectionListDocument: build.mutation<any, AddListDocumentArgs>({
                query: (args) => ({
                    url: `collections/${args.collection_id}/lists/${args.list_id}/documents`,
                    method: "POST",
                    params: {
                        document_id: args.document_id
                    }
                }),
                invalidatesTags: (result, error, arg) => [{ type: "listDocuments", id: arg.list_id }]
            }),
            removeCollectionListDocument: build.mutation<any, AddListDocumentArgs>({
                query: (args) => ({
                    url: `collections/${args.collection_id}/lists/${args.list_id}/documents/${args.document_id}`,
                    method: "DELETE"
                }),
                invalidatesTags: (result, error, arg) => [{ type: "listDocuments", id: arg.list_id }]
            }),
            getListsEnabled: build.query<boolean, CollectionIdArgs>({
                query: (args) => ({
                    url: `collections/${args.collection_id}/lists-enabled`
                })
            }),
            getVisionCapable: build.query<boolean, CollectionIdArgs>({
                query: (args) => ({
                    url: `collections/${args.collection_id}/is-model-vision-capable`
                })
            }),
            getHomescreenType: build.query<string, CollectionIdArgs>({
                query: (args) => ({
                    url: `collections/${args.collection_id}/homescreen-type`
                })
            }),
            getIcebreakers: build.query<string, CollectionIdArgs>({
                query: (args) => ({
                    url: `collections/${args.collection_id}/icebreakers`
                })
            }),
            getWorkingMemoryData: build.query<any, CollectionIdArgs>({
                query: (args) => ({
                    url: `collections/${args.collection_id}/working-memory-data`
                }),
                providesTags: (result, error, arg) => [{ type: "workingMemory", id: arg.collection_id }]
            }),
            updateWorkingMemoryData: build.mutation<any, UpdateWorkingMemoryDataArgs>({
                query: (args) => ({
                    url: `collections/${args.collection_id}/working-memory-data`,
                    method: "PUT",
                    body: args.body
                }),
                onQueryStarted: (args, { dispatch, queryFulfilled }) => {
                    const patchResult = dispatch(
                        collectionsRtkApi.util.updateQueryData(
                            "getWorkingMemoryData",
                            { collection_id: args.collection_id },
                            (draft: any | undefined) => {
                                if (draft) {
                                    const payload: any[] = args.body;
                                    payload.forEach((p) => {
                                        draft.data[p.row][p.col].value = p.value;
                                    });
                                }
                            }
                        )
                    );
                    queryFulfilled.catch(patchResult.undo);
                },
                invalidatesTags: (result, error, arg) => [{ type: "workingMemory", id: arg.collection_id }]
            }),
            getWorkingMemoryFileLink: build.query<string, GetWorkingMemoryFileArgs>({
                query: (args) => ({
                    url: `collections/${args.collection_id}/working-memory-file`,
                    params: args.params
                })
            }),
            uploadWorkingMemoryFile: build.mutation<any, UploadWorkingMemoryFileArgs>({
                query: (args) => ({
                    url: `collections/${args.collection_id}/working-memory-file`,
                    method: "POST",
                    params: { ...args.params, wait_return: false },
                    body: objectToFormData(args.body)
                }),
                invalidatesTags: (result, error, arg) => [{ type: "wf_uploads", id: (arg.collection_id, arg.params.thread_uuid) }]
            }),
            deleteWorkingMemoryFile: build.mutation<any, CollectionIdArgs>({
                query: (args) => ({
                    url: `collections/${args.collection_id}/working-memory-file`,
                    method: "DELETE"
                }),
                invalidatesTags: (result, error, arg) => [{ type: "workingMemory", id: arg.collection_id }]
            }),
            getWorkingDocuments: build.query<KnowledgeDocument[], GetFeedbakcThreadArgs>({
                query: (queryArg) => ({
                    url: `/collections/${queryArg.collection_id}/all-working-memory-files`,
                    params: {
                        thread_uuid: queryArg.thread_uuid
                    }
                }),
                providesTags: (result, error, arg) => [{ type: "workingFiles", id: arg.collection_id }]
            }),
            getWFDocumentsInUpload: build.query<UploadInfo[], WorkingFileArgs>({
                query: (args) => ({
                    url: `/collections/${args.collection_id}/wf-uploads`,
                    params: {
                        thread_id: args.thread_uuid
                    }
                }),
                providesTags: (result, error, arg) => [{ type: "wf_uploads", id: (arg.collection_id, arg.thread_uuid) }]
            }),
            deleteWFile: build.mutation<any, DeleteFileArgs>({
                query: (queryArg) => {
                    return {
                        url: `/collections/${queryArg.collection_id}/files`,
                        method: "DELETE",
                        body: [["file_uid", queryArg.file_id]]
                    };
                },
                invalidatesTags: (result, error, arg) => [{ type: "workingFiles", id: arg.collection_id }]
            })
        }),
        overrideExisting: false
    });

export default collectionsRtkApi;

export type CollectionIdArgs = {
    collection_id: string;
};

export type WorkingFileArgs = CollectionIdArgs & {
    thread_uuid: string | null;
};

export type GetAllCollectionsArgs = {
    org: string;
    validate_admin?: boolean;
};

export type CollectionDescription = {
    uuid: string;
    name: string;
};

export type CollectionInfo = {
    name: string;
    owner_organization_uuid: string;
    cmetadata: {};
};

export type UpdateCollectionNameArgs = CollectionIdArgs & {
    collection_name: string;
};

export type Privilege = "chat_access" | "read_access" | "write_access" | "admin_access";

export type PrivilegesInfo = {
    uuid: string;
    privilege: Privilege[];
};

export type CollectionPrivilegesInfo = {
    user_permissions: PrivilegesInfo[];
    org_permissions: PrivilegesInfo[];
};

export type UpdateCollectionPrivilegsArgs = CollectionIdArgs & {
    body: {
        user_privileges: PrivilegesInfo[];
        org_privileges: PrivilegesInfo[];
    };
};

export type CreateCollectionArgs = {
    params: {
        collection_name: string;
        owner_organization_uuid: string;
    };
    body: {
        user_privileges: PrivilegesInfo[];
        org_privileges: PrivilegesInfo[];
    };
};

type UploadArgs = CollectionIdArgs & {
    title?: string;
    metadata?: string;
    current_org_uuid: string;
};

export type UploadFileArgs = UploadArgs & {
    file: Blob;
};

export type UploadGistArgs = UploadArgs & {
    markdown_text: string;
};

export type GetGistArgs = CollectionIdArgs & {
    doc_uid: string;
};

export type UpdateGistArgs = UploadGistArgs & GetGistArgs;

export type UploadRecArgs = UploadArgs & {
    is_note?: boolean;
    rec: Blob;
};

export type UploadResponse = {
    filename: string;
    task_id: string;
    doc_uid: string;
};

export type UploadInfo = {
    document_id: string;
    collection_id: string;
    upload_start: number;
    task_id: string;
    document_title: string;
    document_type: string;
};

export type RemoveUploadArgs = CollectionIdArgs & {
    doc_uid: string;
};

export type GetGistResponse = {
    title: string;
    body: string;
    uid: string;
};

export type KnowledgeDocument = {
    filename: string;
    title: string;
    pages: number;
    chunks: number;
    file_size: number;
    timestamp: number;
    filetype: string;
};

export type DownloadFileArgs = CollectionIdArgs & {
    file_id: string;
};

export type DeleteFileArgs = DownloadFileArgs;

export type GetDocumentVisibilityArgs = CollectionIdArgs & {
    doc_uuid: string;
};

export type SetDocumentVisibilityArgs = GetDocumentVisibilityArgs & {
    visibility: boolean;
};

export type UpdateDocumentTitleArgs = {
    doc_uid: string;
    collection_id: string;
    params: {
        current_org_uuid: string;
    };
    body: {
        title: string;
    };
};

export type CollectionSettings = {
    llm_model: string;
    retrieval_top_k: number;
};

export type UpdateCollectionSettingsArgs = CollectionIdArgs & {
    payload: CollectionSettings;
};

export type UpdatePromptArgs = CollectionIdArgs & {
    payload: {
        prompt: string;
    };
};

export type GetModelNameResponse = {
    model_name: string;
};

export type UpdateModelPicArgs = CollectionIdArgs & {
    params: {
        current_org_uuid: string;
    };
    payload: {
        pic: Blob;
    };
};

export type UpdatePipelineTypeArgs = CollectionIdArgs & {
    params: {
        pipeline_type: string;
    };
};

export type UpdateModelLanguageArgs = CollectionIdArgs & {
    params: {
        model_language: string;
    };
};

export type PipelineTypeResponse = {
    pipeline_type: string;
    default: boolean;
};

export type ModelLanguageResponse = {
    model_language: string;
    default: boolean;
};

export type UpdateModelNameArgs = CollectionIdArgs & {
    payload: {
        name: string;
    };
};

export type AddAssociatedCollectionArgs = CollectionIdArgs & {
    params: {
        cross_retrieve_collection_id: string;
        current_org_uuid: string;
    };
};

export type RemoveAssociatedCollectionArgs = CollectionIdArgs & {
    cross_retrieve_collection_id: string;
    params: {
        current_org_uuid: string;
    };
};

export type GetFeedbakcThreadArgs = CollectionIdArgs & {
    thread_uuid: string;
};

export type SetFeedbackReadArgs = GetFeedbakcThreadArgs & {
    message_uuid: string;
    params: {
        feedback_read: boolean;
    };
};

export type FeedbackMessageInfo = {
    body: string;
    cmetadata: [];
    created: number;
    feedback_read: boolean;
    feedback_string: string;
    feedback_value: boolean;
    sender: string;
    sender_id: string;
    sender_type: string;
    thread_uuid: string;
    uuid: string;
};

export type ListIdArgs = CollectionIdArgs & {
    list_id: string;
};

export type CreateListArgs = CollectionIdArgs & {
    params: {
        title: string;
        description?: string;
    };
};

export type UpdateListArgs = ListIdArgs & CreateListArgs;

export type AddListDocumentArgs = ListIdArgs & {
    document_id: string;
};

export type ListInfo = {
    uuid: string;
    collection_uuid: string;
    title: string;
    description: string;
};

export type UpdateWorkingMemoryDataArgs = CollectionIdArgs & {
    body: { row: number; col: number; value: string }[];
};

export type GetWorkingMemoryFileArgs = CollectionIdArgs & {
    params: {
        current_org_uuid: string;
    };
};

export type UploadWorkingMemoryFileArgs = CollectionIdArgs & {
    params: {
        current_org_uuid: string;
        force_uuid?: string;
        thread_uuid?: string;
        audio?: boolean;
    };
    body: {
        title: string;
        file: Blob;
    };
};

export const {
    useGetAllCollectionsQuery,
    useLazyGetAllCollectionsQuery,
    useGetCollectionInfoQuery,
    useUpdateCollectionNameMutation,
    useDeleteCollectionMutation,
    useCreateCollectionMutation,
    useGetCollectionPrivilegesQuery,
    useLazyGetCollectionPrivilegesQuery,
    useAddCollectionPrivilegesMutation,
    useRemoveCollectionPrivilegesMutation,
    useGetDocumentsQuery,
    useGetDocumentsInUploadQuery,
    useRemoveDocumentsInUploadMutation,
    useLazyGetDocumentsQuery,
    useUploadFileMutation,
    useUploadGistMutation,
    useLazyGetGistQuery,
    useUpdateGistMutation,
    useUploadNoteMutation,
    useLazyGetNoteQuery,
    useUpdateNoteMutation,
    useUploadRecMutation,
    useDeleteFileMutation,
    useGetDocumentVisibilityQuery,
    useSetDocumentVisibilityMutation,
    useUpdateDocumentTitleMutation,
    useLazyGetDownloadLinkQuery,
    useGetCollectionConfigQuery,
    useUpdateCollectionConfigMutation,
    useGetPromptQuery,
    useUpdatePromptMutation,
    useDeletePromptMutation,
    useGetModelPicQuery,
    useUpdateModelPicMutation,
    useDeleteModelPicMutation,
    useGetModelNameQuery,
    useUpdateModelNameMutation,
    useGetPipelineTypeQuery,
    useUpdatePipelineTypeMutation,
    useDeletePipelineTypeMutation,
    useGetModelLanguageQuery,
    useUpdateModelLanguageMutation,
    useDeleteModelLanguageMutation,
    useGetAssociatedCollectionsQuery,
    useAddAssociatedCollectionMutation,
    useRemoveAssociatedCollectionMutation,
    useGetCollectionFeedbacksQuery,
    useSetCollectionFeedbackReadMutation,
    useGetCollectionFeedbackThreadQuery,
    useRemoveTaskInUploadMutation,
    useGetCollectionListsQuery,
    useCreateCollectionListMutation,
    useEditCollectionListMutation,
    useDeleteCollectionListMutation,
    useGetCollectionListDocumentsQuery,
    useAddCollectionListDocumentMutation,
    useRemoveCollectionListDocumentMutation,
    useGetListsEnabledQuery,
    useGetVisionCapableQuery,
    useGetHomescreenTypeQuery,
    useGetIcebreakersQuery,
    useGetWorkingMemoryDataQuery,
    useUpdateWorkingMemoryDataMutation,
    useLazyGetWorkingMemoryFileLinkQuery,
    useUploadWorkingMemoryFileMutation,
    useDeleteWorkingMemoryFileMutation,
    useGetWorkingDocumentsQuery,
    useGetWFDocumentsInUploadQuery,
    useDeleteWFileMutation
} = collectionsRtkApi;

//*****************/
function sortDocuments(documents: KnowledgeDocument[], sortingProperty: keyof KnowledgeDocument, sortingDescending: boolean): KnowledgeDocument[] {
    return documents.sort((a, b) => {
        if (a[sortingProperty] > b[sortingProperty]) return sortingDescending ? 1 : -1;
        if (a[sortingProperty] < b[sortingProperty]) return sortingDescending ? -1 : 1;
        return 0;
    });
}

function filterDocuments(documents: KnowledgeDocument[], pattern: string, ignoreCase: boolean): KnowledgeDocument[] {
    const filter = ignoreCase ? pattern.toUpperCase() : pattern;
    return documents.filter((doc) => {
        const title = ignoreCase ? doc.title.toUpperCase() : doc.title;
        const replaced = title.replace(/ /g, "_");
        return title.includes(filter) || replaced.includes(filter);
    });
}

export function useFilteredDocuments(
    collectionId: string,
    documentsPerPage: number,
    pageNumber: number,
    filteringPattern: string,
    ignoreCase: boolean,
    sortingProperty: keyof KnowledgeDocument,
    sortingDescending: boolean,
    listId: string
) {
    const docsResponse = useGetDocumentsQuery(
        { collection_id: collectionId },
        { skip: !!listId, refetchOnMountOrArgChange: true, pollingInterval: 5000 }
    );
    const listResponse = useGetCollectionListDocumentsQuery(
        { collection_id: collectionId, list_id: listId },
        { skip: !listId, refetchOnMountOrArgChange: true, pollingInterval: 5000 }
    );
    const uploads = useGetDocumentsInUploadQuery(
        { collection_id: collectionId },
        { skip: !!listId, refetchOnMountOrArgChange: true, pollingInterval: 5000 }
    );

    const allResponse = useMemo(() => {
        const docs = docsResponse.data ?? [];
        const ups =
            uploads.data?.map((up) => ({
                file_uid: up.document_id,
                filename: up.document_id,
                title: up.document_title,
                filetype: up.document_type
            })) ?? [];

        const filenames = ups.map((up) => up.filename);
        const filtered = docs.filter((d) => !filenames.includes(d.filename));

        return { data: [...filtered, ...ups] };
    }, [docsResponse.data, uploads.data]);

    const docs = useMemo(() => {
        const data = listId ? listResponse.data : allResponse.data;
        const documents = data
            ? sortDocuments(filterDocuments(data as KnowledgeDocument[], filteringPattern, ignoreCase), sortingProperty, sortingDescending)
            : [];
        const length = documents.length;
        const startIdx = (pageNumber - 1) * documentsPerPage;
        const endIdx = startIdx + documentsPerPage;
        return {
            documents: documents.slice(startIdx, endIdx),
            documentsCount: length
        };
    }, [allResponse.data, documentsPerPage, filteringPattern, ignoreCase, listId, listResponse.data, pageNumber, sortingDescending, sortingProperty]);

    const allTasks = useAllDocumentTasks();
    const completedTasks = useCompletedTasks();

    useEffect(() => {
        const refetch = listId ? listResponse.refetch : docsResponse.refetch;
        refetch();
    }, [docsResponse.refetch, allTasks, completedTasks, listId, listResponse.refetch]);

    return listId ? { ...listResponse, data: docs } : { ...allResponse, data: docs };
}

export function useFilteredWorkingDocuments(
    collectionId: string,
    threadId: string,
    documentsPerPage: number,
    pageNumber: number,
    filteringPattern: string,
    ignoreCase: boolean,
    sortingProperty: keyof KnowledgeDocument,
    sortingDescending: boolean
) {
    const allResponse = useGetWorkingDocumentsQuery(
        { collection_id: collectionId, thread_uuid: threadId },
        { refetchOnMountOrArgChange: true, pollingInterval: 5000 }
    );
    const docs = useMemo(() => {
        const data = allResponse.data;
        const documents = data ? sortDocuments(filterDocuments(data, filteringPattern, ignoreCase), sortingProperty, sortingDescending) : [];
        const length = documents.length;
        const startIdx = (pageNumber - 1) * documentsPerPage;
        const endIdx = startIdx + documentsPerPage;
        return {
            documents: documents.slice(startIdx, endIdx),
            documentsCount: length
        };
    }, [allResponse.data, documentsPerPage, filteringPattern, ignoreCase, pageNumber, sortingDescending, sortingProperty]);

    const allTasks = useWFAllDocumentTasks();
    const completedTasks = useWFCompletedTasks();

    useEffect(() => {
        const refetch = allResponse.refetch;
        refetch();
    }, [allResponse.refetch, allTasks, completedTasks]);

    return { ...allResponse, data: docs };
}

export function useIsExcel() {
    const collId = useCurrentCollectionId();
    const { data } = useGetHomescreenTypeQuery({ collection_id: collId }, { skip: !collId, refetchOnMountOrArgChange: true });
    return data === "excel";
}

export function useIsCucciTraining() {
    const collId = useCurrentCollectionId();
    const { data } = useGetPipelineTypeQuery({ collection_id: collId }, { skip: !collId, refetchOnMountOrArgChange: true });
    return data ? data.pipeline_type === "pipelines.cucinelli_copy_training_router" : false;
}

export function useIsWorkingDocs() {
    const collId = useCurrentCollectionId();
    const { data } = useGetHomescreenTypeQuery({ collection_id: collId }, { skip: !collId, refetchOnMountOrArgChange: true });
    return data === "working_docs";
}
